<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-primary my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="name"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`name.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model="form.name[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="short_name"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`short_name_${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.short_name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.short_name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    >
                      <b-form-input
                        v-model="form['short_name_' + k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.short_name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.weight')"
                vid="weight"
                rules="numeric"
              >
                <b-form-group
                  :label="$t('labels.weight')"
                >
                  <b-form-input
                    v-model="form.weight"
                    :placeholder="$t('labels.weight')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.height')"
                vid="height"
                rules="numeric"
              >
                <b-form-group
                  :label="$t('labels.height')"
                >
                  <b-form-input
                    v-model="form.height"
                    :placeholder="$t('labels.height')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.club_player_number')"
                vid="club_player_number"
                rules="numeric"
              >
                <b-form-group
                  :label="$t('labels.club_player_number')"
                >
                  <b-form-input
                    v-model="form.club_player_number"
                    :placeholder="$t('labels.club_player_number')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.national_player_number')"
                vid="national_player_number"
                rules="numeric"
              >
                <b-form-group
                  :label="$t('labels.national_player_number')"
                >
                  <b-form-input
                    v-model="form.national_player_number"
                    :placeholder="$t('labels.national_player_number')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.age')"
                vid="age"
                rules="required|numeric"
              >
                <b-form-group
                  :label="$t('labels.age')"
                >
                  <b-form-input
                    v-model="form.age"
                    :placeholder="$t('labels.age')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="foot_playing"
                :name="$t('labels.foot_playing')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.foot_playing')"
                >
                  <v-select
                    v-model="form.foot_playing"
                    :dir="$store.state.appConfig.layout.direction"
                    label="label"
                    :options="foot_playing"
                    :reduce="item => item.value"
                    :placeholder="$t('labels.foot_playing')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="nationality_id"
                :name="$t('nationality')"
                rules="required"
              >
                <b-form-group
                  :label="$t('nationality')"
                >
                  <v-select
                    v-model="form.nationality_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="nationalities"
                    :reduce="item => item.id"
                    :placeholder="$t('nationality')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="position_id"
                :name="$t('labels.player_position')"
              >
                <b-form-group
                  :label="$t('labels.player_position')"
                >
                  <v-select
                    v-model="form.position_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="title_label"
                    :options="positions"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.player_position')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="current_team"
                :name="$t('labels.current_team')"
              >
                <b-form-group
                  :label="$t('labels.current_team')"
                >
                  <v-select
                    v-model="form.current_team"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="teams"
                    :reduce="item => item.id"
                    :placeholder="$t('labels.current_team')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="previous_teams"
                :name="$t('labels.previous_teams')"
              >
                <b-form-group
                  :label="$t('labels.previous_teams')"
                >
                  <v-select
                    v-model="form.previous_teams"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="teams"
                    :reduce="item => item.id"
                    multiple
                    :placeholder="$t('labels.previous_teams')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                vid="image"
                :name="$t('labels.image')"
              >
                <ImagePreview
                  v-model="image"
                  :label="$t('labels.image')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>
            <!-- media -->
            <b-col cols="12">
              <hr>

              <div class="d-flex align-items-center justify-content-between">
                <h6>
                  {{ $t('labels.media') }}
                </h6>
                <b-button
                  variant="primary"
                  class="btn-icon"
                  @click.prevent="addFile"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </div>
              <div
                v-for="(item, index) in files"
                :key="index"
                class="pt-1"
              >
                <div
                  :ref="`files${index}`"
                  class="d-flex align-items-start justify-content-between"
                >
                  <div class="flex-grow-1">
                    <b-form-file
                      size="lg"
                      :placeholder="$t('labels.drop_files_here_or_click_to_upload')"
                      drop-placeholder="Drop file here..."
                      @input="onChangeFile($event, index)"
                    />
                  </div>
                  <div
                    style="width: 50px; height: 50px; border-radius: 4px;overflow: hidden;background: #fff; margin-right: 10px;"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <img
                      style="width: 100%; height: 100%; object-fit: cover;"
                      class="img-fluid"
                      :src="item.file"
                    >
                  </div>
                  <b-button
                    variant="danger"
                    class="btn-icon ml-1"
                    @click="removeFile(index)"
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>
                </div>
              </div>
            </b-col>

            <!-- checkbox -->
            <b-col cols="12">
              <b-card-text class="mb-0">
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded:false,
      image: null,
      nationalities: [],
      positions: [],
      teams: [],
      form: {
        name: {
          en: null,
          ar: null,
        },
        short_name_ar: null,
        short_name_en: null,
        weight: null,
        height: null,
        club_player_number: null,
        national_player_number: null,
        age: null,
        foot_playing: null,
        nationality_id: null,
        position_id: null,
        previous_teams: null,
        current_team: null,
        image: null,
        status: 'active',
        media: [],
      },
      files: [],
    }
  },
  computed: {
    foot_playing() {
      return [
        {
          label: this.$t('labels.right_foot'),
          value: 'right',
        },
        {
          label: this.$t('labels.left_foot'),
          value: 'left',
        },
      ]
    },
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
  },
  created() {
    this.getNationalities()
    this.getPositions()
    this.getTeams()
    if (this.isEdit) {
      this.loadData()
    }else {
      this.isDataLoaded = true
    }
  },
  methods: {
    getNationalities() {
      this.axios.get('/lists/Country')
        .then(res => {
          this.nationalities = res.data
        })
    },
    getPositions() {
      this.axios.get('/lists/PlayerPosition')
        .then(res => {
          this.positions = res.data
        })
    },
    getTeams() {
      this.axios.get('/lists/Team ')
        .then(res => {
          this.teams = res.data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            short_name_ar: data.short_name_ar,
            short_name_en: data.short_name_en,
            weight: data.weight,
            height: data.height,
            club_player_number: data.club_player_number,
            national_player_number: data.national_player_number,
            age: data.age,
            foot_playing: data.foot_playing,
            nationality_id: data.nationality_id,
            position_id: data.position_id,
            current_team: data.current_team,
            previous_teams: data.previous_teams.map(item => item.id),
            status: data.status,
          }
          this.files = data?.media_list.map(x => ({
            id: x.id,
            file: x.file,
          }))
          const $attachments = _.cloneDeep(data.media_list)
          this.form.media = $attachments
          this.image = data.image
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
        media: [
          ...this.files,
        ],
      }
      if (!($form.image instanceof File)) {
        delete $form.image
      }
      $form.media.forEach(x => {
        if (!(x.file instanceof File) && x.file !== 'delete') {
          // eslint-disable-next-line no-param-reassign
          delete x.file
          // eslint-disable-next-line no-param-reassign
          delete x.id
        }
      })
      return $form
    },
    removeFile(index) {
      const file = this.files[index]
      this.files[index] = {
        ...file,
        id: file.id,
        file: 'delete',
      }
      this.$refs[`files${index}`][0].remove()
    },
    addFile() {
      this.files.push({
        file: null,
        id: null,
      })
    },
    onChangeFile(e, index) {
      const file = e
      this.files[index].file = file
    },
  },

}
</script>
